import React, { useEffect, useState } from "react";
import { FaSearch, FaPlus } from "react-icons/fa";
import { Link, useNavigate } from "react-router-dom";
import axios from "axios";

const PAGE_SIZE = 20; // Number of items per page

const ProductAdmin = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [currentPage, setCurrentPage] = useState(1);
    const [filterStatus, setFilterStatus] = useState('all');
    const [products, setProducts] = useState([]);
    const [loading, setLoading] = useState(true);
    const [error, setError] = useState(null);
    const navigate = useNavigate();


    const getAllProducts = async () => {
        setLoading(true);
        setError(null);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL_PRO}/get`);
            const data = response.data.products;
            const reversed = data.reverse();
            setProducts(reversed);
            console.log('products-fetch :', response.data.products);
        } catch (err) {
            console.error('Error fetching Products:', err);
            setError('Failed to fetch products. Please try again later.');
        } finally {
            setLoading(false); // Set loading to false after fetching
        }
    };

    useEffect(() => {
        getAllProducts();
    }, []);

    const handleSearchChange = (event) => {
        setSearchTerm(event.target.value.toLowerCase());
        setCurrentPage(1); // Reset to the first page on search
    };

    const handleFilterChange = (status) => {
        setFilterStatus(status);
        setCurrentPage(1); // Reset to the first page on filter change
    };

    const filteredData = products.filter(item => {
        const matchesSearch = (item.title && item.title.toLowerCase().includes(searchTerm)) ||
            (item.description && item.description.toLowerCase().includes(searchTerm)) ||
            (item.categoryID && item.categoryID.name && item.categoryID.name.toLowerCase().includes(searchTerm));
        const matchesFilter = filterStatus === 'all' ||
            (filterStatus === 'approved' && item.isApproved) ||
            (filterStatus === 'not-approved' && !item.isApproved);
        return matchesSearch && matchesFilter;
    });

    const totalItems = filteredData.length;
    const totalPages = Math.ceil(totalItems / PAGE_SIZE);

    const handlePageChange = (pageNumber) => {
        setCurrentPage(pageNumber);
    };

    const startIndex = (currentPage - 1) * PAGE_SIZE;
    const endIndex = Math.min(startIndex + PAGE_SIZE, totalItems);
    const currentData = filteredData.slice(startIndex, endIndex);

    const productView = (id) => {
        if (id) {
            navigate(`/admin/products/details/${id}`);
        }
    };

    return (
        <div className="p-5 h-screen bg-gray-100 dark:bg-gray-900">
               <div className="mb-4 relative">
                {loading ? (
                    <div className="flex items-center justify-center h-full">
                        <p className="text-lg text-gray-500">Loading...</p>
                    </div>
                ) : (
                    <>
                        {error && (
                            <div className="mb-4 p-2 bg-red-100 text-red-700 border border-red-300 rounded">
                                {error}
                            </div>
                        )}
                        <input
                            type="text"
                            placeholder="Search..."
                            value={searchTerm}
                            onChange={handleSearchChange}
                            className="w-full p-2 pl-10 text-gray-700 bg-white dark:bg-boxdark dark:text-bodydark shadow-md dark:shadow-none rounded-lg focus:outline-none"
                        />
                        <FaSearch className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500 dark:text-gray-400" />
                    </>
                )}
            </div>

            <div className="flex justify-end mb-4">
                <button
                    onClick={() => handleFilterChange('all')}
                    className={`px-4 py-2 mx-2 text-sm font-medium rounded-lg ${filterStatus === 'all' ? 'text-blue-500 hover:underline dark:text-blue-500' : 'bg-gray-400'}`}
                >
                    All Products
                </button>
                <button
                    onClick={() => handleFilterChange('approved')}
                    className={`px-4 py-2 mx-2 text-sm font-medium rounded-lg ${filterStatus === 'approved' ? 'text-blue-500 hover:underline dark:text-blue-500' : 'bg-gray-400'}`}
                >
                    Approved Products
                </button>
                <button
                    onClick={() => handleFilterChange('not-approved')}
                    className={`px-4 py-2 mx-2 text-sm font-medium rounded-lg ${filterStatus === 'not-approved' ? 'text-blue-500 hover:underline dark:text-blue-500' : 'bg-gray-400'}`}
                >
                    Not Approved Products
                </button>
            </div>

            {/* Table view */}
            <div className="overflow-auto rounded-lg shadow-md hidden md:block bg-white dark:bg-boxdark dark:text-bodydark dark:shadow-none">
                <table className="w-full">
                    <thead className="bg-gray-50 border-b-2 border-gray-200 dark:bg-gray-700 dark:border-gray-600">
                        <tr>
                            <th className="w-16 p-3 text-sm font-semibold tracking-wide text-left text-gray-600 dark:text-gray-300">S.No</th>
                            <th className="p-3 text-sm font-semibold tracking-wide text-center text-gray-600 dark:text-gray-300">Title</th>
                            <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left text-gray-600 dark:text-gray-300">Image</th>
                            <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left text-gray-600 dark:text-gray-300">Price</th>
                            <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left text-gray-600 dark:text-gray-300">Qty/Qlt/booking</th>
                            <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left text-gray-600 dark:text-gray-300">Category</th>
                            <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left text-gray-600 dark:text-gray-300">Approved</th>
                            <th className="p-3 text-sm font-semibold tracking-wide text-left text-gray-600 dark:text-gray-300">Created At</th>
                            <th className="p-3 text-sm font-semibold tracking-wide text-left text-gray-600 dark:text-gray-300">Updated At</th>
                            <th className="w-24 p-3 text-sm font-semibold tracking-wide text-left text-gray-600 dark:text-gray-300">Actions</th>
                        </tr>
                    </thead>
                    <tbody className="divide-y divide-gray-100 dark:divide-gray-700 text-center">
                        {currentData.map((item, index) => (
                            <tr key={item._id} className="hover:bg-gray dark:hover:bg-body cursor-pointer"
                                onClick={productView.bind(this, item._id)}>
                                <td className="p-3 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap">{startIndex + index + 1}.</td>
                                <td className="p-3 text-sm text-gray-700 dark:text-gray-300">{item.title}</td>
                                <td className="p-3 text-sm text-gray-700 dark:text-gray-300">
                                    {item.images ? (
                                        <img src={item.images} alt={item.title} className="w-16 h-10 object-cover rounded" />
                                    ) : (
                                        <span className="text-gray-500">No Image</span>
                                    )}
                                </td>
                                <td className="p-3 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap">
                                    ₹{item.price}
                                </td>
                                <td className="p-3 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap">
                                    {!item.quantity && item.quantity !== 0 ? '-' : item.quantity}
                                </td>
                                <td className="p-3 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap">
                                    <span className="p-1.5 text-xs font-semibold rounded-lg bg-green-200 text-green-800">
                                        {item.categoryID && item.categoryID.name ? item.categoryID.name : 'N/A'} {/* Adjusted if `categoryID` is an object */}
                                    </span>
                                </td>
                                <td className="p-3 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap">
                                    <span className={`p-1.5 text-xs font-semibold rounded-lg ${item.isApproved ? 'bg-green-200 text-green-800' : 'bg-red-200 text-red-800'}`}>
                                        {item.isApproved ? 'Approved' : 'Not Approved'}
                                    </span>
                                </td>
                                <td className="p-3 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap">{new Date(item.createdAt).toLocaleDateString()}</td>
                                <td className="p-3 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap">{new Date(item.updatedAt).toLocaleDateString()}</td>
                                <td className="p-3 text-sm text-gray-700 dark:text-gray-300 whitespace-nowrap">
                                    <Link to={`/admin/products/details/${item._id}`}
                                        className="text-success hover:underline dark:text-success">
                                        View
                                    </Link>
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>


            {/* Mobile view */}
            <div className="block md:hidden">
                {currentData.map((item, index) => (
                    <div key={item._id} className="bg-white dark:bg-boxdark rounded-lg shadow-md mb-4 p-4 dark:bg-gray-800 dark:text-gray-200">
                        <div className="mb-2">
                            {item.images ? (
                                <img src={item.images} alt={item.title} className="w-full h-full object-cover rounded" />
                            ) : (
                                <div className="w-full h-32 bg-gray-200 rounded flex items-center justify-center text-gray-500">No Image</div>
                            )}
                        </div>
                        <div className="flex items-center justify-between mb-2">
                            <h2 className="text-lg font-semibold">{item.title}</h2>
                        </div>
                        {/* <p className="text-sm text-gray-700 dark:text-gray-300 mb-2">{item.description}</p> */}
                        <p className="text-sm text-gray-700 dark:text-gray-300 mb-2">Price: ₹{item.price}</p>
                        <p className="text-sm text-gray-700 dark:text-gray-300 mb-2">Quantity: {item.quantity}</p>
                        <p className="text-sm text-gray-700 dark:text-gray-300 mb-2">Category: {item.categoryID && item.categoryID.name ? item.categoryID.name : 'N/A'}</p>
                        <span className={`p-1.5 text-xs font-medium uppercase tracking-wider ${item.isApproved ? 'text-green-800 bg-green-200 dark:text-green-200 dark:bg-green-600' : 'text-red-800 bg-red-200 dark:text-red-200 dark:bg-red-600'} rounded-lg`}>
                            {item.isApproved ? 'Approved' : 'Not-Approved'}
                        </span>
                        <p className="text-sm text-gray-700 dark:text-gray-300 mb-2 mt-2">createdAt : {new Date(item.createdAt).toLocaleDateString()}</p>
                        <p className="text-sm text-gray-700 dark:text-gray-300 mb-2">updatedAt : {new Date(item.updatedAt).toLocaleDateString()}</p>
                        <div className="flex justify-end">
                            <Link to={`/admin/products/details/${item._id}`}
                                className="text-success hover:underline dark:text-success">
                                View
                            </Link>
                        </div>
                    </div>
                ))}
            </div>

            {/* Pagination */}
            <div className="flex justify-center mt-4">
                <nav>
                    <ul className="flex list-none space-x-1">
                        {[...Array(totalPages)].map((_, index) => (
                            <li key={index}>
                                <button
                                    onClick={() => handlePageChange(index + 1)}
                                    className={`px-4 py-2 mx-1 text-sm font-medium rounded-lg ${currentPage === index + 1 ? 'bg-blue-500 text-white' : 'bg-gray-200 text-gray-700'} dark:bg-gray-800 dark:text-gray-300`}
                                >
                                    {index + 1}
                                </button>
                            </li>
                        ))}
                    </ul>
                </nav>
            </div>
        </div>
    );
};

export default ProductAdmin;
