import React from "react";
import { Link } from "react-router-dom";

const categories = [
    {
        image: "https://static1.backyardbossimages.com/wordpress/wp-content/uploads/2022/12/germinating-seeds.jpg",
        title: "Seeds and Plants",
        altText: "Seeds and Plants",
        category:"AgriInput"
    },
    {
        image: "https://www.healthyeating.org/images/default-source/home-0.0/nutrition-topics-2.0/general-nutrition-wellness/2-2-2-2foodgroups_vegetables_detailfeature.jpg?sfvrsn=226f1bc7_6",
        title: "Vegetables",
        altText: "Vegetables",
        category:"AgriOutput"
    },
    {
        image: "https://www.usatoday.com/gcdn/-mm-/b2b05a4ab25f4fca0316459e1c7404c537a89702/c=0-0-1365-768/local/-/media/2021/05/08/USATODAY/usatsports/imageForEntry35-1sQ.jpg?width=1365&height=768&fit=crop&format=pjpg&auto=webp",
        title: "Fruits",
        altText: "Fruits",
        category:"AgriOutput"
    },
    {
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcS0762BZhr1I2YoVmvgdQZkpMY7eFtVCFOLTQ&s",
        title: "Dairy",
        altText: "Dairy",
        category:"AgriServices"
    },
    {
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcQNQ2STyGn1wSiRuQKEkCD2OBIejOF_-SskEw&s",
        title: "Irrigations",
        altText: "Irrigations",
        category:"AgriInput"
    },
    {
        image: "https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcT94sohfgmTOsqaJHGtkfAVnbEAdDvdNDnDVw&s",
        title: "Farm Machinery",
        altText: "Farm Machinery",
        category:"AgriInput"
    },
    {
        image: "https://cdn.images.express.co.uk/img/dynamic/13/590x/plant-food-soil-1423845.jpg?r=1619102206248",
        title: "Nutrients Plants",
        altText: "Nutrients Plants",
        category:"AgriServices"
    },
];

const CategorySuggestions = () => {
    return (
        <div className="lg:mx-5 p-4 mb-5 bg-white dark:bg-boxdark-2 shadow-md dark:shadow-none">
            <div className="flex space-x-5 mb-2">
                <h3 className="text-2xl font-bold">Categories You May LiKe</h3>
                <span>
                    <Link
                        to="/products"
                        className="text-[12px] text-success hover:underline"
                    >
                        View All
                    </Link>
                </span>
            </div>
            <div>
                <div className="grid grid-cols-2 lg:grid-cols-7 md:grid-cols-3 mx-auto gap-4">
                    {categories.map((category, index) => (
                        <div
                            key={index}
                            className="bg-white dark:bg-boxdark shadow-lg rounded-lg p-1 lg:w-40 text-sm cursor-pointer"
                        >
                            <div className="text-center">
                                <div className="overflow-hidden mb-5">
                                    <img
                                        src={category.image}
                                        alt={category.altText}
                                        className="mx-auto mb-2 transform hover:scale-125 transition-transform duration-300"
                                        style={{ width: '100%', height: '100px' }}
                                    />
                                </div>
                                <Link
                                to={`/${category.category}`}
                                    className="font-semibold hover:text-success"
                                >
                                    {category.title}
                                </Link>
                                <button className="bg-green-500 text-white px-4 py-1 mt-5 font-bold w-full rounded hover:bg-green-600 transition">
                                    <a href="#enquiry">Get Quotes</a>
                                </button>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CategorySuggestions;
