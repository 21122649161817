import React, { useEffect, useState } from "react";
import axios from "axios";
import { BiSolidPhoneCall } from "react-icons/bi";
import Spinner from "./loading";
import VendorEnquiry from "../home/vendorEnquiry";
import { Link, useNavigate } from "react-router-dom";
import Toast from "../../commons/Toastify";
import { Helmet } from 'react-helmet'; // Import Helmet

const Products = ({ user, isproPage, setState, searchCity, searchState, category, subcategory, subsubcategory, subsescategory,
    selectedPriceRange, paramsValue, subparamsValue, subsubparamsValue, productnameparamsValue }) => {
    const [products, setProducts] = useState([]);
    const [filteredProducts, setFilteredProducts] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [itemsPerPage] = useState(isproPage && isproPage ? 8 : 12); // Number of products per page
    const [loading, setLoading] = useState(true);
    const [searchTerm, setSearchTerm] = useState(''); // State to store search term
    const [isVendorEnquiryOpen, setIsVendorEnquiryOpen] = useState(false);
    const [viewPhone, setViewPhone] = useState(null);

    const [vendorID, setVendorID] = useState('');
    const [categoryID, setCategoryID] = useState('');

    const [toast, setToast] = useState({ show: false, type: '', title: '', message: '' });
    const navigate = useNavigate();


    const getAllProducts = async () => {
        setLoading(true);
        try {
            const response = await axios.get(`${process.env.REACT_APP_API_URL_PRO}/approved/get`);
            const data = response.data.products;
            const reversed = data.reverse();
            setProducts(reversed);
            setFilteredProducts(reversed);

            // Accumulate all locations in an array
            const locations = reversed.map((item) => ({
                country: item.country,
                state: item.state,
                city: item.city,
                nearby: item.nearby,
                postal_code: item.postal_code
            }));
            setState(locations);

        } catch (err) {
            console.error('Error fetching Products:', err);
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        getAllProducts();
        setSearchTerm(productnameparamsValue);
    }, [paramsValue, subparamsValue, productnameparamsValue]);

    useEffect(() => {
        let filtered = products;

        if (searchCity) {
            filtered = filtered.filter(product =>
                product.city.toLowerCase().includes(searchCity.toLowerCase())
            );
        }

        if (searchState) {
            filtered = filtered.filter(product =>
                product.state.toLowerCase().includes(searchState.toLowerCase())
            );
        }

        if (category) {
            filtered = filtered.filter(product =>
                product.categoryID.name.toLowerCase().includes(category.toLowerCase())
            );
        }

        if (subcategory || subparamsValue) {
            filtered = filtered.filter(product =>
                (subcategory && product.subcategoryID.name.toLowerCase().includes(subcategory.toLowerCase())) ||
                (subparamsValue && product.subcategoryID.name.toLowerCase().includes(subparamsValue.toLowerCase()))
            );
        }

        if (subsubcategory || subsubparamsValue) {
            filtered = filtered.filter(product =>
                (subsubcategory && product.subsubcategoryID.name.toLowerCase().includes(subsubcategory.toLowerCase())) ||
                (subsubparamsValue && product.subsubcategoryID.name.toLowerCase().includes(subsubparamsValue.toLowerCase()))
            );
        }

        if (subsescategory) {
            filtered = filtered.filter(product =>
                product.subsescategoryID.name.toLowerCase().includes(subsescategory.toLowerCase())
            );
        }

        // Filter based on the search term
        if (searchTerm) {
            filtered = filtered.filter(product =>
                product.title.toLowerCase().includes(searchTerm.toLowerCase())
            );
        }

        setFilteredProducts(filtered);

    }, [searchCity, searchState, products, category, subcategory, subsubcategory, subsescategory,
        selectedPriceRange, paramsValue, subparamsValue, searchTerm]);

    // Calculate the indices for the current page
    const indexOfLastProduct = currentPage * itemsPerPage;
    const indexOfFirstProduct = indexOfLastProduct - itemsPerPage;
    const currentProducts = filteredProducts.slice(indexOfFirstProduct, indexOfLastProduct);

    // Change page
    const paginate = (pageNumber) => setCurrentPage(pageNumber);

    // Calculate total pages
    const pageNumbers = [];
    for (let i = 1; i <= Math.ceil(filteredProducts.length / itemsPerPage); i++) {
        pageNumbers.push(i);
    }

    if (loading) {
        return <Spinner />; // Show spinner while loading
    }

    // Function to get the token from local storage
    const getToken = () => localStorage.getItem('token');

    const sendEnquiry = (vendorID, categoryID) => {
        const token = getToken();

        if (!token) {
            // Show the success toast
            setToast({
                show: true,
                type: 'info',
                title: 'info',
                message: 'Please Login First!',
            });

            // Show the success toast for 1 seconds, then navigate
            setTimeout(() => {
                navigate('/login');
            }, 1000);
            return;
        }

        setIsVendorEnquiryOpen(true);
        setVendorID(vendorID);
        setCategoryID(categoryID);
    };


    const viewNumber = (productId) => {
        const token = getToken();

        if (!token) {
            // Show the success toast
            setToast({
                show: true,
                type: 'info',
                title: 'info',
                message: 'Please Login First!',
            });

            // Show the success toast for 1 seconds, then navigate
            setTimeout(() => {
                setToast({ show: false });
            }, 1000);
            return;
        }
        setViewPhone(prevId => prevId === productId ? null : productId);
    };

    const handleClick = (productId) => {
        localStorage.setItem('productId', productId);
    };

    return (
        <>

            {/* Dynamic SEO Metadata */}
            <Helmet>
                <title>{category ? `${category} Products - Best Deals` : 'All Products - Best Deals'}</title>
                <meta name="description" content={`Browse and find the best deals on ${category || 'various products'} from verified vendors.`} />
                <meta name="keywords" content={`${category}, best deals, top products, verified vendors`} />
            </Helmet>


            {/* Product List */}
            <div className="grid grid-cols-2 lg:grid-cols-4 md:grid-cols-3 mx-auto gap-4">
                {currentProducts.map((item, i) => (
                    <div key={i + 1} className="bg-white dark:bg-boxdark shadow-lg rounded-lg flex flex-col p-1 lg:w-50 text-sm cursor-pointer">
                        <Link to={`/details/${item.title.replace(/\s+/g, '-')}`} className="block flex-grow"
                            onClick={handleClick.bind(this, item._id)}
                        >
                            <div className="text-center">
                                <div className="overflow-hidden">
                                    <img
                                        src={item.images}
                                        alt="Product"
                                        className="mx-auto mb-2 transform hover:scale-125 transition-transform duration-300"
                                        style={{ width: '100%', height: '100px' }}
                                    />
                                </div>
                                <h3 className="font-semibold mb-1">{item.title}</h3>
                                <p className="mb-1">{item.brandID.name}</p>
                                <p className="font-bold mb-2">₹{item.price}
                                    {item.quantity ? '/' : ''}
                                    <span className="text-success ms-1">
                                        {item.quantity && item.quantity}
                                    </span>
                                        {item.instock !== undefined && item.instock !== null && item.categoryID.name !== 'AgriOutput' &&
                                        item.categoryID.name !== 'AgriServices' ? (
                                            <span>
                                                <span className="mx-1 text-xl font-bold">{item.instock ? '-' : ''}</span>
                                                <span className={`${typeof item.instock === 'number' && item.instock > 0 ? 'text-success' : 'text-danger'}`}>
                                                    {typeof item.instock === 'number' && item.instock > 0 ? 'In Stock' : 'Out of Stock'} {item.instock ? item.instock : ''}
                                                </span>
                                            </span>
                                        ) : null}
                                </p>
                                <p className="line-through text-danger">
                                    {item.cutprice ? `₹${item.cutprice}` : ''}
                                </p>
                            </div>
                        </Link>
                        <div className="text-center mt-2">
                            <button
                                className="flex items-center font-bold lg:px-8 px-5 py-1 rounded text-amber-500 hover:text-blue-500"
                                onClick={() => viewNumber(item._id)}
                            >
                                <BiSolidPhoneCall className="mr-2" />
                                <span>
                                    {viewPhone === item._id ? item.vendorID.phone : "View Number"}
                                </span>
                            </button>

                            <button
                                className="bg-green-500 text-white px-4 py-2 font-bold w-full rounded hover:bg-green-600 transition mt-2"
                                onClick={() => sendEnquiry(item.vendorID._id, item.categoryID._id)}
                            >
                                Get Best Price
                            </button>
                        </div>
                    </div>
                ))}

            </div>

            {/*  Pagination section */}
            <div className={`flex items-center justify-center mt-4 ${isproPage && isproPage ? 'hidden' : 'block'}`}>
                <button
                    onClick={() => paginate(currentPage - 1)}
                    disabled={currentPage === 1} // Disable if on the first page
                    className={`px-4 py-2 border rounded ${currentPage === 1 ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-white text-blue-500'}`}
                >
                    Pre
                </button>

                <span className="mx-4">
                    Next {currentPage} of {pageNumbers.length}
                </span>

                <button
                    onClick={() => paginate(currentPage + 1)}
                    disabled={currentPage === pageNumbers.length} // Disable if on the last page
                    className={`px-4 py-2 border rounded ${currentPage === pageNumbers.length ? 'bg-gray-300 text-gray-500 cursor-not-allowed' : 'bg-white text-blue-500'}`}
                >
                    Next
                </button>
            </div>



            {/* Vendor Enquiry */}
            {isVendorEnquiryOpen && (
                <VendorEnquiry
                    onCancel={() => setIsVendorEnquiryOpen(false)}
                    onConfirm={() => setIsVendorEnquiryOpen(false)}
                    vendorID={vendorID}
                    categoryID={categoryID}
                    buyerID={user._id}
                />
            )}

            {/* Toast notification */}
            {toast.show && (
                <div className="fixed top-20 left-1/2 transform -translate-x-1/2 z-50">
                    <Toast type={toast.type} title={toast.title} message={toast.message} onClose={() => setToast({ show: false })} />
                </div>
            )}
        </>
    );
};

export default Products;


