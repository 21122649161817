import React from 'react';
import { FaFacebookF, FaTwitter, FaInstagram, FaLink } from "react-icons/fa";

const Footer = () => {
  return (
    <footer className=" bottom-0 bg-success text-white w-full px-4 border-t-4 dark:bg-boxdark dark:text-bodydark shadow-md dark:shadow-none">
      <div className="container  flex flex-col justify-between py-10 mx-auto space-y-8 lg:flex-row lg:space-y-0">
        <div className="lg:w-1/3">
          <a rel="noopener noreferrer" href="#" className="flex justify-center space-x-3 lg:justify-start">
            <div className="flex items-center justify-center w-16 h-16">
              <img
                src="https://res.cloudinary.com/dq7vggsop/image/upload/v1711532480/jfqcm2s1ekxloucjk72p.png"
                alt="Logo"
              />
            </div>
            <span className="self-center text-xl font-semibold">Karnataka Agribusiness</span>
          </a>
        </div>
        <div className="grid grid-cols-2 text-sm gap-x-6 gap-y-8 lg:w-2/3 sm:grid-cols-3">
          <div className="space-y-3">
            <h3 className="tracking-wide uppercase dark:text-gray-900">About Us</h3>
            <ul className="space-y-3">
              <li><a rel="noopener noreferrer" href="#" className='hover:underline'>User guidelines</a></li>
              <li><a rel="noopener noreferrer" href="#" className='hover:underline'>Feedback</a></li>
              <li><a rel="noopener noreferrer" href="#" className='hover:underline'>Address</a></li>
              <li><a rel="noopener noreferrer" href="#" className='hover:underline'>Location</a></li>
            </ul>
          </div>
          <div className="space-y-3">
            <h3 className="uppercase dark:text-gray-900">Vendor Tool Kit</h3>
            <ul className="space-y-3">
              <li><a rel="noopener noreferrer" href="#" className='hover:underline'>Who can be a Vendor?</a></li>
              <li><a rel="noopener noreferrer" href="#" className='hover:underline'>Guideline for vendor product</a></li>
              <li><a rel="noopener noreferrer" href="#" className='hover:underline'>Guidelines for Service Vendor</a></li>
              <li><a rel="noopener noreferrer" href="#" className='hover:underline'>Get your leads</a></li>
            </ul>
          </div>
          <div className="space-y-3">
            <h3 className="uppercase dark:text-gray-900">Buyers Tool Kit</h3>
            <ul className="space-y-3">
              <li><a rel="noopener noreferrer" href="#" className='hover:underline'>Post your requirement</a></li>
              <li><a rel="noopener noreferrer" href="#" className='hover:underline'>Guidelines for Buyer</a></li>
              <li><a rel="noopener noreferrer" href="#" className='hover:underline'>Search products and suppliers</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="flex justify-between items-center py-5">
        <div className="text-center flex-grow">
          <p className="text-sm">&copy; 2024 Karnataka Agribusiness All rights reserved.</p>
        </div>
        <div className="flex space-x-4">
          <a href="#" aria-label="Facebook" title='Facebook'>
            <FaFacebookF className=' hover:text-primary' />
          </a>
          <a href="#" aria-label="Twitter" title='Twitter'>
            <FaTwitter className=' hover:text-secondary' />
          </a>
          <a href="#" aria-label="Instagram" title='Instagram'>
            <FaInstagram className=' hover:text-danger' />
          </a>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
