import React, { useState, useEffect } from "react";
import LocationFilter from "./locationFilter";
import AllFilter from "./allfilter";
import RelatedProduct from "./relatedProduct";
import Products from "./products";
import { useParams } from "react-router-dom";


const CategoriesWiseProducts = ({ user }) => {
    const [state, setState] = useState([]);
    const [searchCity, setSearchCity] = useState('');
    const [searchState, setSearchState] = useState('');

    // All Categories wise data 
    const [category, setCategory] = useState('');
    const [subcategory, setSubCategory] = useState('');
    const [subsubcategory, setSubSubCategory] = useState('');
    const [subsescategory, setSubsesCategory] = useState('');
    // const [isStock, setIsStock] = useState('');
    const [selectedPriceRange, setSelectedPriceRange] = useState('');

    const { slug, subslug, subsubslug, city, productname } = useParams();
    const [paramsValue, setParamsValue] = useState(slug);
    const [subparamsValue, setSubParamsValue] = useState(subslug);
    const [subsubparamsValue, setSubsubParamsValue] = useState(subsubslug);

    const [cityparamsValue, setCityParamsValue] = useState('');
    const [productnameparamsValue, setProductnameParamsValue] = useState('');

    useEffect(() => {
        setParamsValue(slug);
        setSubParamsValue(subslug);
        setSubsubParamsValue(subsubslug);
        setCityParamsValue(city);
        setProductnameParamsValue(productname);
    }, [slug, subslug, subsubslug, city, productnameparamsValue]);



    // Function to reset cityparamsValue
    const resetCityParams = () => {
        setCityParamsValue('');
        setProductnameParamsValue('');
    };


    return (
        <div className="mt-5 mb-5">
            {/* Title */}
            <div className="flex flex-col sm:flex-row justify-between mx-4 sm:mx-10 mb-5">
                <div className="text-xs sm:text-sm whitespace-nowrap mb-2 sm:mb-0 sm:me-5">
                    All Products
                </div>
                <div className="font-bold text-amber-600 text-xs sm:text-sm text-justify">
                    Find the best deals and get personalized assistance to meet your needs.
                </div>
            </div>



            {/* Header filter */}
            <div>
                <LocationFilter
                    state={state}
                    setSearchCity={setSearchCity}
                    setSearchState={setSearchState}
                    cityparamsValue={cityparamsValue}
                    resetCityParams={resetCityParams}
                />
            </div>

            <div className="grid md:grid-cols-4 lg:mx-10 gap-5">

                {/* filterations section */}
                <div className="relative col-span-4 md:col-span-1">
                    <AllFilter
                        setCategory={setCategory}
                        setSubCategory={setSubCategory}
                        setSubSubCategory={setSubSubCategory}
                        setSubsesCategory={setSubsesCategory}
                        // setIsStock={setIsStock}
                        setSelectedPriceRange={setSelectedPriceRange}
                        paramsValue={paramsValue}
                        subparamsValue={subparamsValue}
                    />
                </div>


                <div className="col-span-3">

                    {/* Related Product */}
                    <RelatedProduct />

                    {/* <SerachBox /> */}

                    {/* Products card */}
                    <div className="col-span-3 mt-2">
                        <Products
                            user={user}
                            setState={setState}
                            searchCity={searchCity}
                            searchState={searchState}
                            category={category}
                            subcategory={subcategory}
                            subsubcategory={subsubcategory}
                            subsescategory={subsescategory}
                            // isStock={isStock}
                            selectedPriceRange={selectedPriceRange}
                            paramsValue={paramsValue}
                            subparamsValue={subparamsValue}
                            subsubparamsValue={subsubparamsValue}
                            productnameparamsValue={productnameparamsValue}
                        />
                    </div>
                </div>
            </div>
        </div>
    )
};
export default CategoriesWiseProducts;