import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import CategorySection from "./categorySection";
import ProductSuggetions from "../product/productSuggest";
import CategorySuggetions from "../product/categorySuggest";
import SerachBox from "./searchbox";


const Home = ({ user, isproPage }) => {

    return (
        <>
            {/* Hero Section */}
            <section data-aos="fade-up" className="relative mb-5 h-[60vh] w-full bg-cover bg-center flex justify-center items-center">
                <div className="relative z-10 flex justify-center items-center h-full w-full">
                    <img
                        src="https://res.cloudinary.com/dq7vggsop/image/upload/v1719577785/zgrsxlskgjetcyjyz10t.jpg"
                        alt="home.pic"
                        className="absolute inset-0 object-cover w-full h-full"
                    />
                    <div className="text-center max-w-screen-lg px-4 md:px-8 lg:px-20 rounded-md relative z-20">
                        <h2 className="text-xl lg:text-3xl font-bold shadow-slate-600 text-green-700 py-3 lg:px-10 px-2 mb-3 border border-green-600 rounded-md bg-white shadow-md">
                            Welcome to Karnataka Agribusiness
                        </h2>
                        <a href="#enquiry">
                            <button className="py-3 px-6 md:px-8 bg-green-600 hover:bg-green-700 text-white font-semibold rounded-md shadow-md">
                                Tell us what you want?
                            </button>
                        </a>
                    </div>
                </div>
            </section>

            <SerachBox />

            {/* Category Sections */}
            <div>
                <CategorySection />
            </div>

            {/* Product You May Like Sections */}
            <div>
                <ProductSuggetions user={user} isproPage={isproPage}/>
            </div>

            {/* Categories You May Like Sections */}
            <div>
                <CategorySuggetions />
            </div>
        </>
    );
};

export default Home;
